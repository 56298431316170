import React, {Suspense, lazy} from 'react';
import {Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Spinner from './components/Spinner';
import InfoBanner from './components/InfoBanner';

const LoginPage = lazy(() => import('./pages/LoginPage'));
const ProfessionalServicesPage = lazy(() => import('./pages/ProfessionalServicesPage'));
const PrefLoginPage = lazy(() => import('./pages/PrefLoginPage'));
const ForgotPwPage = lazy(() => import('./pages/ForgotPwPage'));
const MFASetupPage = lazy(() => import('./pages/MFASetupPage'));
const ForgotUsernamePage = lazy(() => import('./pages/ForgotUsernamePage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const UpdatePasswordPage = lazy(() => import('./pages/UpdatePasswordPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const RedirectPage = lazy(() => import('./pages/Redirect'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

const App = () => {
  return (
    <div className="App-outer-container">
      <div className="App-inner-container">
        <InfoBanner></InfoBanner>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login/signin" element={<LoginPage />} />
            <Route path="/professional" element={<ProfessionalServicesPage />} />
            <Route path="/forgotpw" element={<ForgotPwPage />} />
            <Route path="/mfa-setup" element={<MFASetupPage />} />
            <Route path="/forgotusername" element={<ForgotUsernamePage />} />
            <Route exact path="/changepw" element={<ResetPasswordPage />} />
            <Route exact path="/updatepw" element={<UpdatePasswordPage />} />
            <Route exact path="/preflogin" element={<PrefLoginPage />} />
            <Route path="/signup" element={<div>SIGN UP</div>} />
            <Route path="/login/logout" element={<LogoutPage />} />
            <Route exact path="/settings/mfa" element={<SettingsPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/redirect" element={<RedirectPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
